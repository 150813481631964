import Description from "./Description";
import { useEffect } from "react";

type ModalProps = {
  title?: string;
  close?: () => void;
  description?: string;
  children?: object;
  width?: string;
  height?: string;
  hideCloseButton?: boolean;
  padding?: string;
  paddingMobile?: string;
  heightMobile?: string;
};
const Modal = ({
  title,
  close,
  description,
  children,
  width,
  height,
  hideCloseButton = false,
  padding,
  paddingMobile,
  heightMobile
}: ModalProps) => {
  const toogleBodyOverflow = (state) => {
    document.body.style.overflow = state == "CLOSE" ? "" : "hidden";
    document.body.style.height = state == "CLOSE" ? "" : "100%";
    document.getElementsByTagName("html")[0].style.overflow =
      state == "CLOSE" ? "" : "hidden";
    document.getElementsByTagName("html")[0].style.height =
      state == "CLOSE" ? "" : "100%";
  };

  useEffect(() => {
    return () => {
      toogleBodyOverflow("CLOSE");
    };
  }, []);

  useEffect(() => {
    toogleBodyOverflow("OPEN");
  }, []);

  return (
    <div className="modal-wrapper display-block">
      <div className="modal-main">
        {hideCloseButton ? (
          ""
        ) : (
          <button onClick={close} className="close-btn">
            X
          </button>
        )}
        <div className="content">
          {title && <h3>{title}</h3>}
          {description && (
            <Description
              fontSize="16px"
              font="Roboto"
              align="left"
              text={description}
              maxWidth="670px"
              width="100%"
            />
          )}
          {children}
        </div>
      </div>

      <style jsx>{`
        .modal-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          z-index: 21;
        }
        .modal-main {
          position: fixed;
          background: white;
          max-width: ${width ? width : "780px"};
          width: ${width ? "100%" : "80%"};
          max-height: ${height ? height : "546px"};
          height: 100%;
          top: 50%;
          left: 50%;
          border-radius: 16px;
          transform: translate(-50%, -50%);
          padding: ${padding ? padding : "50px 40px 40px 40px"};
        }
        .content {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: ${padding ? "0px" : "10px 10px 0 5px"};
        }
        .display-block {
          display: block;
        }
        .close-btn {
          position: absolute;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          right: 20px;
          top: 15px;
          font-size: 15px;
        }
        .content h3 {
          color: #404040;
          font-weight: bold;
          font-size: 24px;
          font-weight: bold;
          line-height: 31px;
          text-align: center;
        }
        @media only screen and (max-width: 320px) {
          .modal-main {
            width: 95% !important;
            padding: 50px 10px 20px 10px !important;
          }
        }
        @media only screen and (max-width: 600px) {
          .modal-main {
            width: 85%;
            max-height: ${heightMobile ? heightMobile : "460px"};
            padding: ${paddingMobile ? paddingMobile : "50px 15px 20px 15px"};
          }
          .content {
            padding: 0;
          }
        }
      `}</style>
    </div>
  );
};
export default Modal;
